import "../sass/style.scss";

const headings = document.querySelectorAll(".accordion__heading");

Array.prototype.forEach.call(headings, (h) => {
    let btn = h.querySelector("button");
    let target = h.nextElementSibling;

    btn.onclick = () => {
        let expanded = btn.getAttribute("aria-expanded") === "true";

        btn.setAttribute("aria-expanded", !expanded);
        target.hidden = expanded;
    };
});

//Trim whitespace from map svg
let mapSvg = document.getElementById("map");

if (mapSvg) {
    let bbox = mapSvg.getBBox();
    let viewBox = [bbox.x, bbox.y, bbox.width, bbox.height].join(" ");
    mapSvg.setAttribute("viewBox", viewBox);
} else {
    null;
}

//Mobile menu
const hamburgerIcon = document.querySelector("#hamburgerIcon");
const closeIcon = document.querySelector("#closeIcon");
const mobileNavigation = document.querySelector(".mobileNavigation");
const windowWidth = window.innerWidth;

//show mobile menu on hamburger icon click
hamburgerIcon.addEventListener("click", () => {
    if (windowWidth >= 460) {
        mobileNavigation.style.display = "grid";
    } else {
        mobileNavigation.style.display = "block";
    }

    closeIcon.style.display = "block";
    hamburgerIcon.style.display = "none";

    document.body.style.overflow = "hidden";
});

//Hide mobile menu on X icon click
closeIcon.addEventListener("click", () => {
    mobileNavigation.style.display = "none";
    closeIcon.style.display = "none";
    hamburgerIcon.style.display = "block";

    document.body.style.overflow = "scroll";
});

//Prevent breaking mobile menu - mobile layout vs. tablet layout
window.addEventListener("resize", () => {
    const windowWidth = window.innerWidth;
    if (windowWidth >= 460 && mobileNavigation.style.display == "block") {
        mobileNavigation.style.display = "grid";
    }

    if (windowWidth < 460 && mobileNavigation.style.display == "grid") {
        mobileNavigation.style.display = "block";
    }
});
